//<![CDATA[
function setCookie(nombre, valor, tiempo)
{
	var dominio				= window.location.hostname;
	var arr_dominio			= dominio.split(".");
	while(arr_dominio.length > 2)
	{
		arr_dominio.shift();
	}
    var dominio_principal	= arr_dominio.join(".");
    var currentDate = new Date();
    var expiration = new Date(currentDate.setFullYear(currentDate.getFullYear() + 1)).toGMTString();
	var thecookie 			= nombre + " = " + escape(valor) + "; expires=" + expiration + "; path=/; domain=" + dominio_principal;
	document.cookie			= thecookie;
}
function getCookie(nombre)
{
	var nombreCookie, valorCookie, cookie = null, cookies = document.cookie.split(';');
	for(i=0; i<cookies.length; i++)
	{
		valorCookie 		= cookies[i].substr(cookies[i].indexOf('=') + 1);
		nombreCookie 		= cookies[i].substr(0,cookies[i].indexOf('=')).replace(/^\s+|\s+$/g, '');
		if(nombreCookie == nombre)	cookie 	= unescape(valorCookie);
	}
	return cookie;
}
jQuery(document).ready(function()
{
	// var msghtml		= '';
	// if(navigator.userAgent.indexOf( "AdobeAIR" ) < 0)
	// {
	// 	if(!getCookie('cookie-accept'))
	// 	{
			
	// 		msghtml += '		<div id="cookies">';
	// 		msghtml += '        	<div class="centrar">';
	// 		msghtml += '	        	<p>Utilizamos cookies propias y de terceros para facilitar y mejorar la navegación, mostrarte contenido relacionado con tus preferencias y recopilar información estadística. Si continúas navegando, consideramos que aceptas su uso. <a href="es/site/privacidad" title="Ver nuestra política de cookies">Más información</a>.</p>';
	// 		msghtml += '                <a href="/politica-cookies" title="Consultar Política de Cookies" class="boton">Consultar Política de Cookies</a>';
	// 		msghtml += '                <a id="hide-msg-cookie" href="aceptar-cookies/" title="Aceptar" class="boton">Aceptar</a>';
	// 		msghtml += '			</div>';
	// 		msghtml += '        </div>';
	// 		jQuery("body").append(msghtml);
	// 		jQuery("body").addClass('cookies');
	// 	}
    // }
    if(navigator.userAgent.indexOf( "AdobeAIR" ) < 0) {
        if(!getCookie('cookie-accept')) {
           $('#cookies').show();
        }
    }
	jQuery("#hide-msg-cookie").click(function()
	{
		jQuery("#cookies").fadeOut("slow", function() 
		{
			jQuery("#cookies").removeClass();
		});
		setCookie('cookie-accept', 'hide', 'null');
		jQuery("body").removeClass('cookies');
		return(false);
	});
}); 
//]]>